@font-face {
  font-family: 'Ogg';
  src: url('/fonts/OggRegular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ogg';
  src: url('/fonts/OggLight.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  background-color: #f5f2ee;
}
